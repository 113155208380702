import React from 'react';
import { Typography } from '@material-ui/core';
import Layout from '../components/Layout';

const FourOhFour = () => {
    return (
        <Layout heading='Page Not Found' title='Page Not Found'>
            <Typography variant='h4'>
                404: We could not find the page you are looking for
            </Typography>
        </Layout>
    );
};
export default FourOhFour;
